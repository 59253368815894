// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./i6Aq5GGX3-0.js";
import * as localizedValues1 from "./i6Aq5GGX3-1.js";

const cycleOrder = ["ampbksG7Z", "KfnIaRvQO"];

const serializationHash = "framer-Xiaf2"

const variantClassNames = {ampbksG7Z: "framer-v-kxq3d0", KfnIaRvQO: "framer-v-1cy0kxi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qFQHIOpJU: localizedValues1, VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ampbksG7Z", "Variant 2": "KfnIaRvQO"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ampbksG7Z"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ampbksG7Z", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1p1ksmq = activeVariantCallback(async (...args) => {
await delay(() => setVariant("KfnIaRvQO"), 5000)
})

useOnVariantChange(baseVariant, {default: onAppear1p1ksmq})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kxq3d0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ampbksG7Z"} ref={ref ?? ref1} style={{backgroundColor: "rgb(224, 224, 224)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({KfnIaRvQO: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1qakhwj"} layoutDependency={layoutDependency} layoutId={"l4KaTIzvC"} style={{backgroundColor: "rgb(252, 252, 255)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Xiaf2.framer-1kmq3mg, .framer-Xiaf2 .framer-1kmq3mg { display: block; }", ".framer-Xiaf2.framer-kxq3d0 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 27px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 579px; will-change: var(--framer-will-change-override, transform); }", ".framer-Xiaf2 .framer-1qakhwj { flex: none; height: 100%; overflow: visible; position: relative; width: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Xiaf2.framer-kxq3d0 { gap: 0px; } .framer-Xiaf2.framer-kxq3d0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Xiaf2.framer-kxq3d0 > :first-child { margin-left: 0px; } .framer-Xiaf2.framer-kxq3d0 > :last-child { margin-right: 0px; } }", ".framer-Xiaf2.framer-v-1cy0kxi .framer-1qakhwj { width: 580px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 579
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KfnIaRvQO":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameri6Aq5GGX3: React.ComponentType<Props> = withCSS(Component, css, "framer-Xiaf2") as typeof Component;
export default Frameri6Aq5GGX3;

Frameri6Aq5GGX3.displayName = "slider-time-mobile";

Frameri6Aq5GGX3.defaultProps = {height: 27, width: 579};

addPropertyControls(Frameri6Aq5GGX3, {variant: {options: ["ampbksG7Z", "KfnIaRvQO"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameri6Aq5GGX3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})